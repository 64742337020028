import React, { Component } from "react";
import Helmet from "react-helmet";

export default class OneTrustScript extends Component {
    render() {
        return (
            <Helmet>
                <script
                    src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
                    type="text/javascript"
                    charset="UTF-8"
                    data-domain-script={`${
                        process.env.GATSBY_BUILD === "develop" ||
                        process.env.GATSBY_BUILD == undefined
                            ? process.env.GATSBY_ONETRUST_SCRIPT_KEY_DEV
                            : process.env.GATSBY_ONETRUST_SCRIPT_KEY
                    }`}
                />
                <script
                    dangerouslySetInnerHTML={{
                        __html: `
                                function OptanonWrapper() {}
                            `,
                    }}
                />
            </Helmet>
        );
    }
}

/**
The following values are used as identifiers for Google Analytics
and Google Tag Manager.

These should be applied to clickable elements (links, buttons, etc) in a 
data attribute i.e.

```
data-gtm={headerNav}
```
 */

export const headerNav = "gtm-header_nav";
export const footerNav = "gtm-footer_nav";
export const whereToStart = "gtm-where_to_start_icon";
export const needSpecific = "gtm_need_something_specific";
export const meetUsBranch = "gtm-branch-meet_us";
export const meetUsTeam = "gtm-team-meet_us";
export const meetMe = "gtm-lo-meet_me";
export const homeLoanTypeIcons = "gtm-home_loans_icon";
export const specialtyLoanIcons = "gtm-specialty_loans_icon";
export const refinanceIcons = "gtm-refinance_icons";
export const blogTile = "gtm-blog_tile";
export const blogCategory = "gtm-blog_categories"; // and header
export const teamLoanOfficerPhoto = "gtm-lo_image";

export default {
    headerNav,
    footerNav,
    whereToStart,
    needSpecific,
    meetUsBranch,
    meetMe,
    homeLoanTypeIcons,
    specialtyLoanIcons,
    refinanceIcons,
    blogTile,
    blogCategory,
    teamLoanOfficerPhoto,
};

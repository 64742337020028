import React from "react";

import styles from "./styles.module.less";

const LinkButton = ({ label, href, target = null, dataGtm }) => (
    <a
        className={styles.LinkButton}
        href={href}
        target={target}
        data-gtm={dataGtm}>
        {label}
    </a>
);

export default LinkButton;

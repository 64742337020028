import React, { Component } from "react";

const OneTrustButton = ({ buttonRef = null }) => {
    return (
        <button
            id="ot-sdk-btn"
            className="ot-sdk-show-settings"
            ref={buttonRef}>
            Cookie Settings
        </button>
    );
};

export default OneTrustButton;

/* eslint-disable no-useless-escape */
import states from "./states.json";
import { isString } from "lodash";
import unified from "unified";
import markdown from "remark-parse";
import html from "remark-html";

export const getFirestoreId = (siteKey, id) => {
    return `${siteKey}|${id}`;
};

export const calculatorIntParse = commaStr =>
    parseInt(commaStr.replace(/[^\d]/g, "")); // takes string of numbers with commas and return int

export const returnStateAbbrev = longFormatState =>
    states.find(state => state.value === longFormatState)?.label || "";

export const formatPhoneAreaCode = phoneNumber => {
    if (!isString(phoneNumber)) {
        return phoneNumber;
    }
    // find extension
    const extension = extractTelephoneExtension(phoneNumber);
    let num = phoneNumber.split(extension)[0].replace(/[^0-9]+/g, "");

    if (num.charAt(0) === "1") {
        num = num.slice(1);
    }
    const formattedNumber =
        "(" +
        num.substring(0, 3) +
        ") " +
        num.substring(3, 6) +
        "-" +
        num.substring(6, num.length);
    return extension ? formattedNumber + " ext. " + extension : formattedNumber;
};

export const formatPhoneNumber = phoneNumber => {
    if (!isString(phoneNumber)) {
        return phoneNumber;
    }
    const extension = extractTelephoneExtension(phoneNumber);
    let num = phoneNumber.split(extension)[0].replace(/[^0-9]+/g, "");
    const formattedNumber = num.replace(/[^0-9]+/g, "");
    return extension ? formattedNumber + "," + extension : formattedNumber;
};

const extractTelephoneExtension = phoneNumber => {
    if (phoneNumber.split("x").length > 1) {
        let i = phoneNumber.split("x").length - 1;
        return phoneNumber.split("x")[i].replace(/[^0-9]+/g, "");
    }
    return null;
};

export const accessiblityLinkSites = {
    CMS: "cms",
    G2H: "g2h",
    CORP: "corp",
    COMM: "comm",
};

export const showAccessibilityLink = site => {
    const sites = process.env.GATSBY_ACCESSIBILITY_LINKS_SHOW.toLowerCase().split(
        ",",
    );
    return sites.includes(site);
};

export const getDocumentByRef = async ref => {
    if (ref) {
        let doc = await ref.get();
        if (doc.exists) {
            const { id } = doc;
            let data = doc.data();
            return { id, ...data };
        }
    }
    return null;
};

export const toHtml = txt => {
    let processed = unified()
        .use(markdown)
        .use(html)
        .processSync(txt);

    return processed.contents;
};

export const getApplyUrl = (applyNow, applyNowScript, slug) => {
    const consumerConnect = /bayequityhomeloans\.mymortgage-online\.com/i;
    const pageLink = `https://bayequityhomeloans.com${slug}applynow/`;
    if (!!applyNowScript) {
        return pageLink;
    }
    return consumerConnect.test(applyNow) ? applyNow : pageLink;
};

import React from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown/with-html";

import { isString } from "lodash";

import styles from "./styles.module.less";

interface HTMLContentProps {
    content: string;
    className: string;
}
export const HTMLContent = (props: HTMLContentProps) => {
    const { content, className = "" } = props;

    let cls = `${styles.HTMLContent} ${className}`;
    // If HTML is not a string, it's a JSX node. Render it as children.
    return isString(content) ? (
        <ReactMarkdown
            className={cls}
            source={cleanupContent(content)}
            escapeHtml={false}
        />
    ) : (
        <div className={cls}>{content}</div>
    );
};

interface ContentProps {
    content: string;
    className: string;
}
const Content = (props: ContentProps) => {
    const { content, className } = props;

    return <div className={className}>{content}</div>;
};

Content.propTypes = {
    content: PropTypes.node,
    className: PropTypes.string,
};

HTMLContent.propTypes = {
    content: PropTypes.string,
    className: PropTypes.string,
};

const cleanupContent = (content: string) => {
    return sanitizeUrls(content).replace(/(?:\r\n|\r|\n)/g, "<br>");
};

function sanitizeUrls(content: string) {
    // add http to link if not there
    return content.replace(/[<]*(<* href="([^"]+)">([^<]+)<\/a>)/gi, link => {
        if (!link.includes("http")) {
            let linkMatch = link.match(/"([^"]+)"/);

            if (linkMatch && linkMatch.length > 1) {
                return link.replace(
                    /href="([^"]+)/,
                    `href="http://${linkMatch[1]}`,
                );
            }

            return "";
        }
        return link;
    });
}

export default Content;
